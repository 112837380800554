import React, { Component } from 'react'

class Banner extends Component {
  render() {
    let { title, image } = this.props

    return (
      <div className='banner'>
        <div className='banner__inner'>
          <div className='banner__image'>
            <img alt={ title } src={ image && image.localFile.childImageSharp.original.src } />
          </div>
          <div className='banner__text'>
            <span>More — <br />{ title }</span>
          </div>
        </div>
      </div>
    )
  }
}

Banner.defaultProps = {
  title: 'of what you would expect.',
  image: null,
}

export default Banner
