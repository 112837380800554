import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            instagram
            facebook
            youtube
            twitter
            banner {
              title
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
            menu_items {
              text
              url
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header location={location} data={data} />
          <main>{ children }</main>
          <Footer data={data} />
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
