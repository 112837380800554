import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Logo from '../components/logo'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(this.scrollDetect)
      this.ticking = true
    }
  }

  scrollDetect = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    let nextState = currentScroll > window.innerHeight
    if (nextState !== scrolled) this.setState({ scrolled: nextState })

    this.ticking = false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let color = offCanvas ? '#000000' : '#ffffff'

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let { pathname } = this.props.location
    let transRoutes = ['/', '/sell', '/sell/']
    let transparent = transRoutes.includes(pathname)

    let headerClass = `header`
    if (offCanvas) headerClass += ' header--inverse'
    if (transparent && !scrolled) headerClass += ' header--transparent'

    let { menu_items } = this.props.data.wordpressAcfOptions.options

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='More - Re Homepage' className='logo' {...props}>
              <Logo color={color} />
            </Link>
            <nav>
              <ul>
                <li><Link className='nav-item' to='/buy' {...props}>Buy</Link></li>
                <li><Link className='nav-item' to='/sell' {...props}>Sell</Link></li>
                <li><Link className='nav-item' to='/about' {...props}>About</Link></li>
                <li><Link className='nav-item' to='/contact' {...props}>Contact</Link></li>
                <li>
                  <div className='nav-item nav-item--extended'>
                    More
                    <svg width="3px" height="9px" viewBox="0 0 3 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g fill={color}>
                          <circle cx="1.5" cy="1.5" r="1.5"></circle>
                          <circle cx="1.5" cy="7.5" r="1.5"></circle>
                        </g>
                      </g>
                    </svg>

                    <ul className='nav-item__extended-menu'>
                      { menu_items.map((el, i) => {
                        if (el.url.indexOf('//') !== -1 || el.url === '') {
                          return (
                            <li key={i}>
                              <a href={el.url || el.file.link} target='_blank' rel='nofollow noopener noreferrer' onClick={this._hideOffCanvas}>
                                { el.text }
                              </a>
                            </li>
                          )
                        }
                        return (
                          <li key={i}>
                            <Link to={el.url} {...props}>
                              { el.text }
                            </Link>
                          </li>
                        )
                      }) }
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
            <div className='header__mobile' onClick={this._toggleOffCanvas}>
              <svg width="5px" height="15px" viewBox="0 0 5 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g fill={color} stroke="none" strokeWidth="1" fillRule="evenodd">
                  <circle cx="2.4" cy="2.4" r="2.4"></circle>
                  <circle cx="2.4" cy="12" r="2.4"></circle>
                </g>
              </svg>
              <span>Menu</span>
            </div>

          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <nav>
            <ul>
              <li><Link to='/buy' {...props}>Buy</Link></li>
              <li><Link to='/sell' {...props}>Sell</Link></li>
              <li><Link to='/about' {...props}>About</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

Header.defaultProps = {
  location: {},
  data: {},
}

export default Header
