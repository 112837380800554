import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="153px" height="18px" viewBox="0 0 153 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon fill={color} points="12.7184152 0.346119231 9.76217379 11.9028115 9.67846345 11.9028115 6.58223586 0.346119231 7.03448276e-05 0.346119231 7.03448276e-05 17.6396192 3.90491172 17.6396192 3.90491172 4.87519615 4.01640828 4.87519615 7.61419448 17.6396192 11.2401186 17.6396192 14.8104703 4.87519615 14.9219669 4.87519615 14.9219669 17.6396192 19.1616497 17.6396192 19.1616497 0.346119231"></polygon>
          <g transform="translate(0.000000, 0.315069)">
            <path d="M27.3041338,14.6620038 C29.0613476,14.6620038 29.9258855,13.2621577 29.9258855,11.2305808 C29.9258855,9.19935 29.0613476,7.79950385 27.3041338,7.79950385 C25.5191338,7.79950385 24.7101683,9.19935 24.7101683,11.2305808 C24.7101683,13.2621577 25.5191338,14.6620038 27.3041338,14.6620038 M27.3041338,17.6538115 C23.3433683,17.6538115 20.6379062,15.0188885 20.6379062,11.2305808 C20.6379062,7.46996538 23.3433683,4.80735 27.3041338,4.80735 C31.2648993,4.80735 33.9981476,7.46996538 33.9981476,11.2305808 C33.9981476,15.0188885 31.2648993,17.6538115 27.3041338,17.6538115" id="Fill-2" fill={color}></path>
            <path d="M44.2890683,9.00726923 C43.6475234,8.65038462 43.0341166,8.51330769 42.1692269,8.51330769 C40.4957234,8.51330769 39.3800545,9.44653846 39.3800545,11.5601538 L39.3800545,17.3246538 L35.3637166,17.3246538 L35.3637166,5.13692308 L39.3800545,5.13692308 L39.3800545,7.36026923 C39.8541786,5.98776923 41.1372683,4.80738462 42.8107717,4.80738462 C43.5638131,4.80738462 44.2053579,5.02684615 44.5401993,5.30134615 L44.2890683,9.00726923 Z" fill={color}></path>
            <path d="M54.6637345,9.99553846 C54.5800241,8.78780769 53.7991966,7.68946154 52.2649759,7.68946154 C50.6473966,7.68946154 49.8943552,8.70507692 49.6710103,9.99553846 L54.6637345,9.99553846 Z M58.4570793,13.3168846 C57.8155345,16.0892308 55.6119828,17.6538462 52.3768241,17.6538462 C48.4438448,17.6538462 45.6546724,15.2934231 45.6546724,11.4230769 C45.6546724,7.57973077 48.499769,4.80738462 52.2931138,4.80738462 C56.3931621,4.80738462 58.5407897,7.57973077 58.5407897,10.9014231 L58.5407897,12.1091538 L49.6154379,12.1091538 C49.7269345,13.7288077 50.9541,14.6620385 52.5161069,14.6620385 C53.9106931,14.6620385 54.775231,14.1403846 55.221569,13.0150385 L58.4570793,13.3168846 Z" id="Fill-6" fill={color}></path>
            <polygon fill={color} points="63.6156414 12.0267692 70.8674897 12.0267692 70.8674897 8.568 63.6156414 8.568"></polygon>
            <polygon fill={color} points="70.2255931 12.0267692 77.4774414 12.0267692 77.4774414 8.568 70.2255931 8.568"></polygon>
            <polygon fill={color} points="76.8351931 12.0267692 84.0870414 12.0267692 84.0870414 8.568 76.8351931 8.568"></polygon>
            <polygon fill={color} points="83.4451448 12.0267692 90.6969931 12.0267692 90.6969931 8.568 83.4451448 8.568"></polygon>
            <polygon fill={color} points="90.0547448 12.0267692 97.3065931 12.0267692 97.3065931 8.568 90.0547448 8.568"></polygon>
            <polygon fill={color} points="96.6646966 12.0267692 103.916545 12.0267692 103.916545 8.568 96.6646966 8.568"></polygon>
            <polygon fill={color} points="103.274297 12.0267692 110.526145 12.0267692 110.526145 8.568 103.274297 8.568"></polygon>
            <polygon fill={color} points="109.883897 12.0267692 117.135745 12.0267692 117.135745 8.568 109.883897 8.568"></polygon>
            <path d="M131.63807,8.10148846 C133.841621,8.10148846 134.929152,7.47010385 134.929152,5.76806538 C134.929152,4.23079615 133.841621,3.37995 131.63807,3.37995 L127.984359,3.37995 L127.984359,8.10148846 L131.63807,8.10148846 Z M135.041001,17.3247577 C134.678373,16.8581423 134.45538,15.73245 134.315746,13.8933346 C134.176463,11.9444885 133.283787,11.3131038 131.526573,11.3131038 L127.984359,11.3131038 L127.984359,17.3247577 L123.828387,17.3247577 L123.828387,0.0312576923 L132.307401,0.0312576923 C136.853787,0.0312576923 139.085125,2.11718077 139.085125,5.43887308 C139.085125,7.99175769 137.439408,9.58371923 135.292132,9.85821923 C137.16049,10.2151038 138.220587,11.2857577 138.416146,13.3169885 C138.666573,15.8695269 138.750635,16.6930269 139.336256,17.3247577 L135.041001,17.3247577 Z" fill={color}></path>
            <path d="M148.901746,9.99553846 C148.818035,8.78780769 148.037208,7.68946154 146.503339,7.68946154 C144.885408,7.68946154 144.132014,8.70507692 143.909373,9.99553846 L148.901746,9.99553846 Z M152.69509,13.3168846 C152.053546,16.0892308 149.849994,17.6538462 146.614483,17.6538462 C142.681856,17.6538462 139.892683,15.2934231 139.892683,11.4230769 C139.892683,7.57973077 142.73778,4.80738462 146.531125,4.80738462 C150.631173,4.80738462 152.778449,7.57973077 152.778449,10.9014231 L152.778449,12.1091538 L143.853097,12.1091538 C143.964946,13.7288077 145.192111,14.6620385 146.754118,14.6620385 C148.148704,14.6620385 149.013242,14.1403846 149.45958,13.0150385 L152.69509,13.3168846 Z" fill={color}></path>
          </g>
        </g>
      </svg>
    )
  }
}

Logo.propTypes = {
  color: PropTypes.string,
}

Logo.defaultProps = {
  color: `#ffffff`,
}

export default Logo
