import React, { Component } from 'react'
import { Link } from 'gatsby'

import Logo from '../components/logo'
import Banner from '../components/banner'
import { Instagram, Facebook, Youtube, Twitter } from '../components/icons'

class Footer extends Component {
  render() {
    let { options } = this.props.data.wordpressAcfOptions

    let props = { title: '' }
    if (options.banner.length) {
      props = options.banner[Math.floor(Math.random() * options.banner.length)]
    }

    return (
      <>
        <Banner {...props} />
        <footer className='footer'>
          <div className='footer__inner'>
            <div>
              <Link to='/' title='More - Re Homepage' className='logo'>
                <Logo />
              </Link>
              <div className='footer__social'>
                { options.instagram && <a href={ options.instagram } target='_blank' rel='nofollow noopener noreferrer'><Instagram /></a> }
                { options.facebook && <a href={ options.facebook } target='_blank' rel='nofollow noopener noreferrer'><Facebook /></a> }
                { options.youtube && <a href={ options.youtube } target='_blank' rel='nofollow noopener noreferrer'><Youtube /></a> }
                { options.twitter && <a href={ options.twitter } target='_blank' rel='nofollow noopener noreferrer'><Twitter /></a> }
              </div>
            </div>
            <div>
              <nav>
                <ul>
                  <li><Link to='/buy'><span>Buy</span></Link></li>
                  <li><Link to='/sell'><span>Sell</span></Link></li>
                  <li><Link to='/about'><span>About</span></Link></li>
                  <li><Link to='/contact'><span>Contact</span></Link></li>
                </ul>
              </nav>
              <p>
                <span>© Copyright More Real Estate Ltd  2023. Licensed REAA 2008</span></p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
